<template>
  <!-- 北美SDE算法速成班 -->
  <div class="">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
<!--            <div class="sch-title">北美SDE算法速成班</div>-->
            <div class="sch-title">2022北美秋招算法速成集训营</div>
            <div class="sch-desc">
              零基础最快的算法求职课程，Edward老师1v1带着刷题，2个月内速成搞定算法<br />
              简历修改指导，面经技巧指导，助你突破公司算法面试，迎战2022北美秋招
            </div>
            <ul class="leet-date">
<!--              <li>-->
<!--                <div class="val">7 <span>人</span></div>-->
<!--                <div class="txt">VIP小班</div>-->
<!--              </li>-->
              <li>
                <div class="val">10 <span>周</span></div>
                <div class="txt">系统学习</div>
              </li>
<!--              <li>-->
<!--                <div class="val">10<span>+</span></div>-->
<!--                <div class="txt">北美内推</div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="val">60 <span>天</span></div>-->
<!--                <div class="txt">1v1电话监督</div>-->
<!--              </li>-->
              <li>
                <div class="val">60 <span>天</span></div>
                <div class="txt">短期迅速突破</div>
              </li>
              <li>
                <div class="val">200<span>+</span></div>
                <div class="txt">小时课程</div>
              </li>
              <li>
                <div class="val">600 <span>道</span></div>
                <div class="txt">LC题目讲解</div>
              </li>
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
              <img src="../../assets/images/scb/shipin.png" alt="" />
              <div class="v-icon"></div>
              <div class="v-title">课程简介 & 免费试听</div>
            </div>
<!--            <div class="countDownText" v-if="countDownTimeStamp != -1">-->
<!--              <span class="text"-->
<!--                >下一期开课：<span class="countsNum">{{ countDays }}</span-->
<!--                >天<span class="countsNum">{{ countHours }}</span-->
<!--                >小时<span class="countsNum">{{ countMinutes }}</span-->
<!--                >分<span class="countsNum">{{ countSeconds }}</span-->
<!--                >秒</span-->
<!--              >-->
<!--            </div>-->
          </div>
        </div>

        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>

        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
			<img src="@/assets/imgs/priceBanner.png" alt="" />
              <div
				class="price-tip"
                style="
                  color: rgba(0, 0, 0, 0.65);
                  text-align: center;
                  font-size: 12px;
                  position: absolute;
                  right: 82px;
                  top: 15px;
                "
              >
                立即报名，立即开始！报名即学，不浪费一分一秒！
              </div>
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div
                    class="prices"
                    v-if="comboData.length > 0"
                    
                  >
                    <span style="font-size: 28px; color: #de1306">
                      ${{
                        comboData[0].usddiscountPrice != 0
                          ? comboData[0].usddiscountPrice
                          : comboData[0].usdprice
                      }}</span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;/&nbsp;&yen;{{
                        comboData[0].discountPrice != 0
                          ? comboData[0].discountPrice
                          : comboData[0].price
                      }}</span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>
                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>
        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
                :current-index="currentIndex"
              />
              <div v-if="stickyToggle" class="price" style="right: 75px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{
                    comboData[0].usddiscountPrice > 0
                      ? comboData[0].usddiscountPrice
                      : comboData[0].usdprice
                  }}</span
                >
                <span style="font-size: 12px; color: #e02020"
                  >/&yen;{{
                    comboData[0].discountPrice > 0
                      ? comboData[0].discountPrice
                      : comboData[0].price
                  }}</span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
          <!-- 速成算法课程体系，零基础短期达到面试水平 -->
          <div class="g-section bg-gray">
            <div class="g-header">
              <h3>速成算法课程体系，零基础短期达到面试水平</h3>
              <p>在最短时间内，体系化学习算法知识体系，具备面试能力</p>
            </div>
            <div class="container">
              <div class="tx-cells">
                <el-row :gutter="30">
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip1.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd">转专业CS</div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          零基础算法从头学起<br />
                          高效的刷题<br />
                          短期算法能力更上层楼
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip2.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd">马上面试</div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          拿到面试机会<br />
                          最短时间内<br />
                          速成算法去面试
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="rel-card hoverUp">
                      <div class="rel-pic">
                        <img src="../../assets/images/scb/shuip3.png" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-hd">科班出身</div>
                        <div class="rel-line"></div>
                        <div class="rel-desc">
                          知识还给老师<br />
                          我们捡起来<br />
                          青出于蓝而胜于蓝
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 完善科学刷题体系，2个月搞定算法面试 -->
          <div class="g-section scb-gtx-section">
            <div class="g-header">
              <h3 style="color: #fff !important">
                完善科学刷题体系，2个月搞定算法面试
              </h3>
              <p style="color: #fff !important">
                经过严苛打磨独特的刷题体系课程，我们的目标只是为大厂面试
              </p>
            </div>
            <div class="container">
              <div class="shut-cells">
                <div class="shut-hd">
                  <div>算<br />法<br />小<br />白</div>
                </div>
                <div class="shut-main">
                  <div class="shut-cell">
                    <el-row :gutter="24">
                      <el-col :span="8">
                        <div class="shut-heading">
                          <h4>《求职面试算法班》</h4>
                          <p>算法基础知识</p>
                        </div>
                        <div class="shut-box">
                          <p>一章搞定Java基础</p>
                          <p>数据结构⾃我实现</p>
                          <p>Java 源码分析</p>
                          <p>LeetCode题⽬实战结合</p>
                          <div class="shut-nn nn1">01<br />夯实基础</div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="shut-heading">
                          <h4>《求职面试刷题班》</h4>
                          <p>题型技巧总结</p>
                        </div>
                        <div class="shut-box">
                          <p>3000题方法总结</p>
                          <p>套路模版汇总</p>
                          <p>科学方法</p>
                          <p>⻅题瞬间想出思路</p>
                          <div class="shut-nn nn2">02<br />题型技巧</div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="shut-heading">
                          <h4>《LeetCode千题视频讲解》</h4>
                          <p>LeetCode 题目视频讲解</p>
                        </div>
                        <div class="shut-box">
                          <p>完善科学 最快刷题</p>
                          <p>⼀题多解 最优解</p>
                          <p>省去找答案 理解答案时间</p>
                          <p>⼀天10题不是梦</p>
                          <div class="shut-nn nn3">03<br />实战演练</div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="shut-grid">
                    <div class="hd">
                      <span>贯穿全程的《CSON每周规划总结》</span>
                      <span class="tag">独享课</span>
                    </div>
                    <div class="bd" style="padding: 37px">
                      <div class="shut-tags">
                        <span>每周规划任务进度</span>
                        <span>每周总结作业题目</span>
                        <span>面试简历修改指导</span>
                        <span>面试实战技巧攻略</span>
                      </div>
                      <!--                                            <div class="shut-tip">-->
                      <!--                                                特别提醒：《CSON每周规划总结》专为CSON速成班独享课程，不单独售卖-->
                      <!--                                            </div>-->
                    </div>
                  </div>
                </div>
                <div class="shut-hd">
                  <div>算<br />法<br />大<br />神</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 横扫北美海外求职面试，突破国内算法求职难题 -->
          <div class="g-section">
            <div class="g-header">
              <h3>打破北美海外求职面试，突破国内算法求职难题</h3>
              <p>偏重于北美一线Google类公司，国内面试对比难度降低</p>
            </div>
            <div class="container">
              <div class="wz-main">
                <div
                  class="wz-panel beimei"
                  @click="curId = 0"
                  :class="{ active: curId === 0 }"
                >
                  <el-row :gutter="30">
                    <el-col :span="10">
                      <div class="wz-map" style="padding-top: 80px">
                        <img src="../../assets/images/scb/beimei.png" />
                        <!--                                                <img src="../../assets/images/scb/NorthAmerica.png"/>-->
                      </div>
                    </el-col>
                    <el-col :span="14">
                      <div class="wz-text">
                        <div class="hd">北美求职面试</div>
                        <div class="bd">
                          零基础转专业，北美找实习、全职、马上毕业OPT即将开始等等<br />
                          体系课程实现从零入门，涉及大厂面试方方面面。
                        </div>
                      </div>
                      <div class="wz-logo">
                        <div class="hd">面试公司</div>
                        <div class="bd">
                          <ul class="logo-list">
                            <li>
                              <img
                                src="../../assets/images/scb/elogo1.png"
                              /><span> Google</span>
                              <!--                                                            <img-->
                              <!--                                                                    src="../../assets/images/scb/Netflix.png"-->
                              <!--                                                            /><span> Netflix</span>-->
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo2.png"
                              /><span> Facebook</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo3.png"
                              /><span> Amazon</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo4.png"
                              /><span> Microsoft</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo7.png"
                              /><span> Apple</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo5.png"
                              /><span> Uber</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo6.png"
                              /><span> Airbnb</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/elogo8.png"
                              /><span> IBM</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div
                  class="wz-panel china"
                  @click="curId = 1"
                  :class="{ active: curId === 1 }"
                >
                  <el-row :gutter="30">
                    <el-col :span="14">
                      <div class="wz-text">
                        <div class="hd">国内求职面试</div>
                        <div class="bd">
                          零基础起步，在职跳槽，2-3个月完成算法短期速成<br />课程内容无缝衔接<!--BAT等-->国内大厂求职面试，录播课程，更高效，更节省时间
                        </div>
                      </div>
                      <div class="wz-logo">
                        <div class="hd">面试公司</div>
                        <div class="bd">
                          <ul class="logo-list">
                            <li>
                              <img
                                src="../../assets/images/scb/clogo1.png"
                              /><span> Baidu</span>
                              <!--                                                               <img-->
                              <!--                                                                    src="../../assets/images/scb/bilibili.png"-->
                              <!--                                                            /><span> 哔哩哔哩</span>-->
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/clogo2.png"
                              /><span> 字节跳动</span>
                              <!--                                                            <img-->
                              <!--                                                                    src="../../assets/images/scb/wangyi.png"-->
                              <!--                                                            /><span> 网易</span>-->
                            </li>
                            <li>
                              <!--   <img
                                                                    src="../../assets/images/scb/clogo3.png"
                                                            /><span> </span> -->
                              <img
                                src="../../assets/images/scb/xiaomi.png"
                              /><span> 小米</span>
                            </li>
                            <li>
                              <img src="../../assets/images/scb/clogo4.png" />
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/clogo5.png"
                              /><span> 阿里巴巴</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/clogo6.png"
                              /><span> 华为</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/clogo7.png"
                              /><span> 美团</span>
                            </li>
                            <li>
                              <img
                                src="../../assets/images/scb/clogo8.png"
                              /><span> 滴滴</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="10">
                      <div class="wz-map" style="padding-top: 20px">
                        <img src="../../assets/images/scb/china.png" />
                        <!--                                                <img src="../../assets/images/scb/china1.png"/>-->
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 高效的刷题路径，适合不同学员快速面试水平 -->
          <div class="g-section">
            <div class="g-header">
              <h3>高效的刷题路径，适合不同学员快速面试水平</h3>
              <p>300题刷题路径，400题刷题路径，目标大厂面试中的核心考察点</p>
            </div>
            <div class="container">
              <div class="rot-main">
                <div class="rot-nn abs1">
                  <div>
                    <i class="iconfont icon-Vfuwu"></i>
                    <p>导师1对1<br />课程咨询</p>
                  </div>
                </div>
                <div class="rot-nn abs2">
                  <div>
                    <i class="iconfont icon-baoming"></i>
                    <p>报名课程</p>
                  </div>
                </div>
                <div class="rot-nn abs3">
                  <div>
                    <i class="iconfont icon-jiqiaofenxi"></i>
                    <p>正式开班</p>
                  </div>
                </div>
                <div class="rot-nn abs4">
                  <div>
                    <i class="iconfont icon-tm"></i>
                    <p>学习投入<br />每天2-3小时<br />一周50题</p>
                  </div>
                </div>
                <div class="rot-nn abs5">
                  <div>
                    <i class="iconfont icon-zhiwei"></i>
                    <p>拿到offer</p>
                  </div>
                </div>
                <div class="sk-cell">
                  <div class="sk-nn">
                    <div>
                      <h3>01</h3>
                      <p>《算法基础知识》</p>
                    </div>
                  </div>
                  <div class="sk-nn">
                    <div>
                      <h3>02</h3>
                      <p>《LC题目讲解》</p>
                    </div>
                  </div>
                  <div class="sk-nn">
                    <div>
                      <h3>03</h3>
                      <p>《题型技巧总结》</p>
                    </div>
                  </div>
                  <div class="sk-text">
                    报名当天<br />提前开始<br />立即开课
                  </div>
                </div>
                <div class="ext-cell">
                  <div class="ext-box">
                    <h4>300刷题路径</h4>
                    <p>
                      适合零基础转专业<br />小白或 2个月内<br />有面试学员选择
                    </p>
                  </div>
                  <div class="ext-box">
                    <h4>400刷题路径</h4>
                    <p>适合有算法基础<br />刷题时间充裕<br />无面试学员选择</p>
                  </div>
                </div>
                <div class="sv-cell">
                  <div class="sv-nn">
                    <div>
                      <h3>01</h3>
<!--                      <p>模拟面试</p>-->
                      <p>电话监督</p>
                    </div>
                  </div>
                  <div class="sv-nn">
                    <div>
                      <h3>02</h3>
                      <p>简历指导</p>
                    </div>
                  </div>
                  <div class="sv-nn">
                    <div>
                      <h3>03</h3>
                      <p>面试指导</p>
<!--                      <p>求职规划</p>-->
                    </div>
                  </div>
                  <div class="sv-text">
                    <span class="iconfont icon-kefu"></span>
                    <p>教学服务</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 最高质量的算法面试课，高效的刷题体系 -->
          <div class="g-section gzl-section">
            <div class="g-header">
              <h3>高质量的算法面试课，高效的刷题体系</h3>
              <p>两个月刷完LeetCode前400题</p>
            </div>
            <div class="container">
              <div class="gz-main">
                <div class="gz-pic">
                  <img
                    style="transform: scale(1.1)"
                    src="../../assets/images/scb/kalepic.png"
                    alt=""
                  />
                </div>
                <div class="gz-col">
                  <el-row :gutter="30">
                    <el-col :span="12">
                      <div class="gz-cell">
                        <div class="hd">科学完善的刷题体系</div>
                        <div class="bd">
                          以面试为目的讲解，拒绝学校老学究方式<br />
                          算法基础知识，题型技巧总结，LeetCode千题视频讲解<br />
                          体系化刷题，最快入门达到面试水平
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="gz-cell">
                        <div class="hd">全面深入的知识内容</div>
                        <div class="bd">
                          从数据结构的实现，到Java源码的深入<br />
                          各种算法讲解，深入到只有面试才有的算法<br />
                          各种做题技巧，模版套路
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="gz-cell">
                        <div class="hd">快速的刷题路线</div>
                        <div class="bd">
                          第一天开始刷题，以面试为目的<br />
                          零基础起步，体系化讲解<br />
                          2个月400题迅速入门
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="gz-cell">
                        <div class="hd">完善的自学体系</div>
                        <div class="bd">
                          如何自己最快速的刷题<br />
                          如何自己修改简历<br />
                          如何在面试中表现，注意事项
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 算法基础知识，题型技巧总结，到LeetCode视频讲解，体系化学习 -->
          <div class="g-section">
            <div class="g-header">
              <h3>从基础到题型，每一道LeetCode题目讲解，体系化系统化学习</h3>
              <p>
                每项技巧套路，直接对应LeetCode实战题型，帮你快速理清思路，短期提升
              </p>
            </div>
            <div class="container">
              <div class="hey-main">
                <el-row>
                  <el-col :span="8">
                    <div class="hey-cell hey-green-cell">
                      <div class="hey-head">算法基础知识</div>
                      <div class="hey-inner-row">
                        <el-row>
                          <el-col :span="12">
                            <div class="col">
                              <div class="hey-button">数据结构</div>
                              <ul class="hey-list">
                                <li class="lt-line">数据结构</li>
                                <li class="lt-line">原理用处</li>
                                <li class="lt-line">代码实现</li>
                                <li class="lt-line">相关算法</li>
                              </ul>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="col">
                              <div class="hey-button">面试深入</div>
                              <ul class="hey-list">
                                <li class="lt-line">源码分析</li>
                                <li class="lt-line">面试补充</li>
                                <li class="lt-line">面试算法</li>
                                <li class="lt-line">LeetCode题目</li>
                              </ul>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="hey-cell hey-blue-cell">
                      <div class="hey-head">题型技巧总结</div>
                      <div class="hey-inner-row">
                        <el-row>
                          <el-col :span="12">
                            <div class="col">
                              <div class="hey-button">题型分类</div>
                              <ul class="hey-list">
                                <li class="rt-line">回溯法</li>
                              </ul>
                              <ul class="hey-list">
                                <li class="rt-line2">动态规划</li>
                              </ul>
                              <ul class="hey-list">
                                <li class="rt-line3">设计</li>
                              </ul>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="col">
                              <div class="hey-button">考点分类</div>
                              <ul class="hey-list blue-bg">
                                <li>回溯三大题型——排列</li>
                                <li>回溯三大题型——子集</li>
                                <li>回溯三大题型——组合</li>
                                <li>模版和值传递</li>
                                <li>回溯法实现题</li>
                              </ul>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="hey-cell hey-orange-cell">
                      <div class="hey-head">LeetCode千题视频讲解</div>
                      <div class="hey-inner-row">
                        <el-row>
                          <el-col :span="12">
                            <div class="col">
                              <div class="hey-button">LeetCode题目</div>
                              <ul class="hey-list">
                                <li class="lt-line">377. Combination Sum IV</li>
                                <li class="lt-line">
                                  93. Restore IP Addresses
                                </li>
                                <li class="lt-line">47. Permutations II</li>
                              </ul>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="col">
                              <div class="hey-button">考频提示</div>
                              <ul class="hey-list">
                                <li class="lt-line">很少考</li>
                                <li class="lt-line">很重要</li>
                                <li class="lt-line">重要</li>
                              </ul>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 算法面试知识点一网打尽，取精华去竞赛算法精准提炼 -->
          <div class="g-section">
            <div class="g-header">
              <h3>算法面试知识点一网打尽，取精华去竞赛算法精准提炼</h3>
              <p>针对面试的算法，只讲面试要考的，过难过偏一律删繁就简</p>
            </div>
            <div class="container">
              <div class="point-cells">
                <el-row :gutter="30">
                  <el-col :span="4">
                    <div class="point-box point-green">时间复杂度</div>
                    <span class="point-box">空间复杂度</span>
                    <span class="point-box">递归</span>
                    <span class="point-box">迭代</span>
                    <span class="point-box">分治</span>
                    <span class="point-box">AVL树</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-green">Array</div>
                    <span class="point-box">基于比较的排序算法</span>
                    <span class="point-box">非比较的排序算法</span>
                    <span class="point-box">Linkedlist</span>
                    <span class="point-box">Stack</span>
                    <span class="point-box">红黑树</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-green">Queue</div>
                    <span class="point-box">HashTable</span>
                    <span class="point-box">HashMap</span>
                    <span class="point-box">HashSet</span>
                    <span class="point-box">Heap</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-green">Graph</div>
                    <span class="point-box">DFS</span>
                    <span class="point-box">BFS</span>
                    <span class="point-box">拓扑排序</span>
                    <span class="point-box">Union Find</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-green">最小生成树</div>
                    <span class="point-box">Prim</span>
                    <span class="point-box">Kruskal</span>
                    <span class="point-box">最短路径</span>
                    <span class="point-box">Dijkstra</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-green">Bellman-Ford</div>
                    <span class="point-box">Floyd-Warshall</span>
                    <span class="point-box">树</span>
                    <span class="point-box">二叉树</span>
                    <span class="point-box">二叉查找树</span>
                  </el-col>
                </el-row>
              </div>
              <div class="point-cells">
                <el-row :gutter="30">
                  <el-col :span="4">
                    <div class="point-box point-blue">位运算</div>
                    <span class="point-box point-shallow-blue">栈</span>
                    <span class="point-box point-shallow-blue">字典树</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-blue">数学题</div>
                    <span class="point-box point-shallow-blue">字符串</span>
                    <span class="point-box point-shallow-blue">线段树</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-blue">数组题</div>
                    <span class="point-box point-shallow-blue">图形堆</span>
                    <span class="point-box point-shallow-blue">树状数组</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-blue">二分查找</div>
                    <span class="point-box point-shallow-blue">回溯法</span>
                    <span class="point-box point-shallow-blue">贪心算法</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-blue">链表</div>
                    <span class="point-box point-shallow-blue">图</span>
                    <span class="point-box point-shallow-blue">设计题</span>
                  </el-col>
                  <el-col :span="4">
                    <div class="point-box point-blue">随机</div>
                    <span class="point-box point-shallow-blue">树</span>
                    <span class="point-box point-shallow-blue">动态规划</span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 自己学慢的理由，80%的时间都可以节省 -->
          <div class="g-section">
            <div class="container">
              <div class="ly-cells">
                <div class="g-header">
                  <h3>自己学慢的理由，可以节省大量的时间</h3>
                  <p>
                    完全可以节省这些机械性工作，让你直达目的，最短速成理解算法
                  </p>
                </div>
                <el-row :gutter="40" style="margin-bottom: 50px">
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/scb/ly1.png" /><span
                          >难题意</span
                        >
                      </div>
                      <div class="bd">
                        很多题意表述不清造成歧义<br />
                        或者有些情况没有说明<br />
                        需要自己猜
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/scb/ly2.png" /><span
                          >找答案</span
                        >
                      </div>
                      <div class="bd">
                        网上答案众多<br />
                        不知道是否这个解法是最优解<br />
                        这个题是否需要一题多解<br />
                        哪些解法有意义，哪些解法不用看
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/scb/ly3.png" /><span
                          >辨优劣</span
                        >
                      </div>
                      <div class="bd">
                        有些解法代码规范一塌糊涂<br />
                        代码累赘重复<br />
                        好的解法一行解决，坏的解法5行<br />
                        初学者很难分辨
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="40" type="flex" justify="center">
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/scb/ly4.png" /><span
                          >看不懂</span
                        >
                      </div>
                      <div class="bd">
                        很多解法只有代码没有注释<br />
                        理解起来费劲<br />
                        经常研究2-3小时也理解不了<br />
                        浪费了大量的时间
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/scb/ly5.png" /><span
                          >没人问</span
                        >
                      </div>
                      <div class="bd">
                        很多小伙伴都是孤军奋战<br />
                        有问题没人讨论，只能自己闭门造车<br />
                        有时候高水平的一句话<br />
                        可以让人豁然开朗
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 面试技巧指导如何和面试官互动表现，简历修改指导让你提升竞争力 -->
          <InterviewGuide></InterviewGuide>
          <!-- 专属教学服务，1对1电话监督，认真负责的课程-->
          <template>
            <div class="g-section">
              <div class="dv-logo">
                <img src="@/assets/images/scb/gray-logo.png" alt="" />
              </div>
              <div class="g-header">
                <h3>专属教学服务，1对1电话监督，认真负责的课程</h3>
                <p>微信群交流，LeetCode题目答疑，服务更全面</p>
              </div>
              <div class="container">
                <div class="jserv-rows">
                  <el-row :gutter="40">
                    <el-col :span="8">
                      <div class="jserv-cell">
                        <div class="hd">
                          <img src="@/assets/images/scb/sv1.png" /><span
                        >电话监督</span
                        >
                        </div>
                        <div class="bd">
                          电话1对1监督<br />
                          明确阶段目标，跟踪刷题进度<br />
                          有问题微信电话随时联系，保证学习效果
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="jserv-cell">
                        <div class="hd">
                          <img src="@/assets/images/scb/sv2.png" />
<!--                          <span>求职规划</span>-->
                          <span>每周周报</span>
                        </div>
<!--                        <div class="bd">-->
<!--                          整体求职路径规划<br />-->
<!--                          Edward老师为学生规划实习全职<br />-->
<!--                          如何在北美规划求职时间线-->
<!--                        </div>-->
                        <div class="bd">
                          每周周报汇报<br />
                          监控刷题进度<br />
                          认真负责的监督每周任务
                        </div>

                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="jserv-cell">
                        <div class="hd">
                          <img src="@/assets/images/scb/sv3.png" />
<!--                          <span>模拟面试</span>-->
                          <span>助教答疑</span>
                        </div>
                        <div class="bd">
                          LeetCode题范围内 <br />
                          有助教答疑<br />
                          保证1对1作答
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </template>
          <!-- 除了录播课程，我们还有什么服务 -->
<!--          <OtherService></OtherService>-->
          <!-- 七大速成学习服务，让你事半功倍，早日拿到心仪Offer -->
          <SevenServices></SevenServices>
          <!--          <course-description></course-description>-->
          <!-- 我们的海内外的学生，都拿到了这些公司的Offer -->
          <offer></offer>
          <free-system></free-system>
          <!-- 价格方案 -->
          <div class="g-section yyh-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                    style="margin: 0 auto !important; width: 900px"
                    v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="12">
                        <div class="gg-yh-cell" style="margin: 0 auto">
                          <div
                            :class="[
                              comboData.length > 1
                                ? 'gg-yh-grid red'
                                : 'gg-yh-grid blue',
                            ]"
                          >
                            <div class="gg-price">
                              ${{ comboData[0].usdprice }}
                            </div>
                            <div class="gg-y-price">
                              ¥{{ comboData[0].price }}
                            </div>
                            <!-- <div
                              class="gg-base-price"
                              v-if="comboData[0].usddiscountPrice != 0"
                            >
                              原价：${{ comboData[0].usdprice }}
                            </div> -->
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                              立即报名，立即开始，前置课程提前学习<br />不浪费一分一秒，报名立刻学习
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >全程以录播为主，随时看随时学
                              </li>
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >全程以1v1为主，Edward老师带着刷题-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i>1v1简历修改 &-->
<!--                                1v1模拟面试-->
<!--                              </li>-->
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1为主，周报制度
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >1v1北美求职规划时间线
                              </li>
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >10+北美FMAG级别公司内推-->
<!--                              </li>-->
                              <li>
                                <i class="iconfont icon-dui"></i
                                >8周时间速成算法去面试
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >班课有效期：3个月
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >课程咨询：添加客服微信号，1v1咨询
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                type="buy"
                                @click="buyCombo"
                                class="blue"
                                >立即购买
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="gg-yh-cell" style="margin: 0 auto">
                          <div :class="['gg-yh-grid red']">
                            <div class="gg-tag-discountTip">
                              <img src="@/assets/imgs/discountTip.png" alt="" />
                            </div>
                            <div class="gg-price">
                              ${{ comboData[0].usddiscountPrice }}
                            </div>
                            <div class="gg-y-price">
                              ¥{{ comboData[0].discountPrice }}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="body-yh">
                              <div class="des">
                                两种课程优惠模式
                                <br />
                                【团购价】2人报名即可团购
<!--                                <br />-->
<!--                                【早鸟价】前3人报名即可获得-->
                                <br /><br />
                                扫码添加微信咨询
                              </div>
                              <img
                                class="qrcode"
                                :src="qrcode.imageUrl"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div class="gg-card">
                    <div
                      class="gg-yh-cell gg-jc-cell"
                      @click="Pcselect('1')"
                      :class="pp[0]"
                      v-if="comboData && comboData.length > 0"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ${{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice || 0
                          }}
                        </div>
                        <div class="gg-y-price">
                          ¥{{
                            comboData[0].discountPrice > 0
                              ? comboData[0].discountPrice
                              : comboData[0].price || 0
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[0].discountPrice != 0"
                        >
                          原价：¥{{ comboData[0].price }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[0].discountPrice == 0"
                        >
                          基础版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          零基础起步，2个月内速成算法面试<br />
                          最全刷题体系，从基础技巧，到LeetCode全面解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >学习周期：2个月<span style="color: #fa6400"
                              >（+额外2个月复习）</span
                            >
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >LC版本不同，有效期不同
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >答疑服务：购买3天内，添加客服微信<br /><span
                              style="padding-left: 19px"
                              >转发海报后即可进群</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-gj-cell"
                      @click="Pcselect('2')"
                      :class="pp[1]"
                      v-if="comboData && comboData.length > 1"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ${{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice || 0
                          }}
                        </div>
                        <div class="gg-y-price">
                          ¥{{
                            comboData[1].discountPrice > 0
                              ? comboData[1].discountPrice
                              : comboData[1].price || 0
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[1].discountPrice != 0"
                        >
                          原价：¥{{ comboData[1].price }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[1].discountPrice == 0"
                        >
                          高级版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          零基础起步，2个月内速成算法面试<br />
                          最全刷题体系，从基础技巧，到LeetCode全面解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >学习周期：2个月<span style="color: #fa6400"
                              >（+额外3个月复习）</span
                            >
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >LC版本不同，有效期不同
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >答疑服务：购买3天内，添加客服微信<br /><span
                              style="padding-left: 19px"
                              >转发海报后即可进群</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-qj-cell"
                      @click="Pcselect('3')"
                      :class="pp[2]"
                      v-if="comboData && comboData.length > 2"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ${{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice || 0
                          }}
                        </div>
                        <div class="gg-y-price">
                          ¥{{
                            comboData[2].discountPrice > 0
                              ? comboData[2].discountPrice
                              : comboData[2].price || 0
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[2].discountPrice != 0"
                        >
                          原价：¥{{ comboData[2].price }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[2].discountPrice == 0"
                        >
                          旗舰版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          零基础起步，2个月内速成算法面试<br />
                          最全刷题体系，从基础技巧，到LeetCode全面解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>学习周期：2个月
                            <span style="color: #fa6400"
                              >（+额外4个月复习）</span
                            >
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >LC版本不同，有效期不同
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >答疑服务：购买3天内，添加客服微信<br /><span
                              style="padding-left: 19px"
                              >转发海报后即可进群</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="gaoduan-yh-popover">
                    <div class="sk-cells">
                      <div class="sk-heading">
                        <div style="width: 20%">LC题目数量</div>
                        <div style="width: 15%">有效期</div>
                        <div style="width: 10%">答疑</div>
                        <div style="width: 15%">阶段测试</div>
                        <div style="width: 15%">电话监督</div>
                        <div style="width: 15%">价格USD</div>
                        <div style="width: 15%">选择</div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('1')"
                        :class="{ active: Pselect === '1' }"
                        v-if="comboData && comboData.length > 0"
                      >
                        <div>1-400题</div>
                        <div>3个月</div>
                        <div>{{ comboData[0].qaDuration }}天</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="1"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('2')"
                        :class="{ active: Pselect === '2' }"
                        v-if="comboData && comboData.length > 1"
                      >
                        <div>1-600题</div>
                        <div>6个月</div>
                        <div>{{ comboData[1].qaDuration }}天</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="2"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('3')"
                        :class="{ active: Pselect === '3' }"
                        v-if="comboData && comboData.length > 2"
                      >
                        <div>1-1000题</div>
                        <div>9个月</div>
                        <div>{{ comboData[2].qaDuration }}天</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="3"></el-radio>
                        </div>
                      </div>
                    </div>
                    <div class="sk-tips">
                      <span class="text-warin"
                        >*点击选中套餐，可立即下单订购</span
                      >
                      <span class="tag">支持花呗分期</span>
                      <span class="tag">支持美元分期付款</span>
                    </div>
                    <div class="skill-yh-foot">
                      <el-button type="warning" plain @click="buyCombo"
                        >立即购买
                      </el-button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <!-- 相关课程推荐 -->
          <div class="g-section">
            <div class="g-header">
              <h3>—— 相关课程推荐 ——</h3>
            </div>
            <div class="container">
              <div class="rel-main" style="padding-bottom: 0">
                <el-row :gutter="30">
                  <el-col
                    :span="12"
                    v-for="(item, index) in recommendClasses"
                    :key="index"
                  >
                    <div
                      class="rel-card hoverUp"
                      @click="changePage(item.courseClassId)"
                    >
                      <div class="rel-pic">
                        <img :src="item.coverUrl" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-desc">
                          {{ item.brief }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="3"></presales-consultation>
        </div>
        <courseMeal v-if="TabActiveIndex == 'course_meal'" id="3"></courseMeal>
        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>

      <!--班期开课时间-->
      <!--            <div class="tx-bar" :class="txShow ? 'show' : 'hidex'">-->
      <!--                <div class="tx-head-bar" @click="txShow = true">-->
      <!--                    <img src="../../assets/images/scb/bq-logo.png" alt=""/><span-->
      <!--                >正式开班时间</span-->
      <!--                >-->
      <!--                </div>-->
      <!--                <div class="tx-bar-bd">-->
      <!--                    <el-radio-group v-model="tradio">-->
      <!--                        <el-radio :label="1" class="tx-radio" disabled>-->
      <!--                            <div class="time">2021年01月11日</div>-->
      <!--                            <div class="cont"><span>第22期</span><span>已开班</span></div>-->
      <!--                        </el-radio>-->
      <!--                        <el-radio :label="2" class="tx-radio" disabled>-->
      <!--                            <div class="time">2021年01月15日</div>-->
      <!--                            <div class="cont"><span>第23期</span><span>已开班</span></div>-->
      <!--                        </el-radio>-->
      <!--                        <el-radio :label="3" class="tx-radio">-->
      <!--                            <div class="time">2021年02月1日</div>-->
      <!--                            <div class="cont"><span>第24期</span><span>开放报名</span></div>-->
      <!--                        </el-radio>-->
      <!--                        <div class="tx-radio">-->
      <!--                            <router-link @click.native ="jumptoPrice" to="" >立即报名、立即开始</router-link>-->
      <!--                        </div>-->
      <!--                    </el-radio-group>-->
      <!--                    <div class="tx-close" @click="txShow = false">-->
      <!--                        <i class="el-icon-arrow-up"></i>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->

      <!-- 提问 -->
      <leetDialog></leetDialog>
    </div>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "@/utils/courseMix";
import Menus from "@/components/course/menu";
import CourseDetail from "@/components/course/courseDetail";
import courseDatas from "@/utils/courseDatas";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import polyVideo from "./components/polyVideo";
import courseDescription from "./components/courseDescription";
import presalesConsultation from "./components/presalesConsultation";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help";
import { getCountDownClass } from "@/service/index";
import InterviewGuide from "./components/interviewGuide";
import TeachService from "./components/teachService";
import OtherService from "./components/otherService";
import SevenServices from "./components/sevenServices";
import { getCourseBanner } from "@/service";
export default {
  components: {
    CourseDetail,
    Menus,
    leetDialog,
    offer,
    freeSystem,
    polyVideo,
    courseDescription,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
    InterviewGuide,
    TeachService,
    OtherService,
    SevenServices,
  },
  mixins: [courseMix, courseDatas],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      bantxt: "2022北美秋招算法速成集训营",
      videoShow: false,
      dr: 3,
      br: 3,
      txShow: true,
      curId: 0,
      tradio: 3,
      Pselect: "1",
      img: require("../../assets/images/scb/banner.jpg"),
      form: {
        region: "v1",
      },
      pp: ["ac1", "ac2", "ac3"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        // { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课大纲", index: "1", tag: "course_content" },
        { name: "用户评价", index: "2", tag: "course_comment" },
        { name: "售前咨询", index: "3", tag: "course_question" },
      ],
      currentIndex: "0",
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
      countDownText: "",
      countDownTimeStamp: -1,
      countDays: 0,
      countHours: 0,
      countMinutes: 0,
      countSeconds: 0,
      qrcode: {},
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCountDownClass();
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "2022北美秋招算法速成集训营":
          this.$router.push("/course/3");
          break;
        // case "北美SDE算法速成班":
        //   this.$router.push("/course/3");
        //   break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    Pcselect(n) {
      this.Pselect = n;
      console.log(n);
      if (n === "1") {
        this.pp = ["ac1", "ac2", "ac3"];
      } else if (n === "2") {
        this.pp = ["ac3", "ac1", "ac2"];
      } else {
        this.pp = ["ac2", "ac3", "ac1"];
      }
      this.selectCombo = this.comboData[parseInt(n) - 1];
    },
    TabActiveIndexChange(index) {
      this.TabActiveIndex = index;
      console.log("------", this.TabActiveIndex);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    // timeCountDown(endDateStr) {
    //   if (endDateStr == -1) {
    //     return;
    //   }
    //   //结束时间
    //   var endDate = new Date(endDateStr);
    //   //当前时间
    //   var nowDate = new Date();
    //   //相差的总秒数
    //   if (endDate < nowDate) {
    //     window.location.reload();
    //   }
    //   var totalSeconds = parseInt((endDate - nowDate) / 1000);
    //   //天数
    //   var days = Math.floor(totalSeconds / (60 * 60 * 24));
    //
    //   //取模（余数）
    //   var modulo = totalSeconds % (60 * 60 * 24);
    //
    //   //小时数
    //   var hours = Math.floor(modulo / (60 * 60));
    //
    //   modulo = modulo % (60 * 60);
    //   //分钟
    //   var minutes = Math.floor(modulo / 60);
    //   //秒
    //
    //   var seconds = modulo % 60;
    //
    //   this.countDays = days;
    //   this.countHours = hours;
    //   this.countMinutes = minutes;
    //   this.countSeconds = seconds >= 10 ? seconds : `0${seconds}`;
    //
    //   setTimeout(() => {
    //     this.timeCountDown(endDateStr);
    //   }, 1000);
    // },
    getCountDownClass() {
      getCountDownClass(3).then((res) => {
        if (res.success) {
          this.countDownTimeStamp = res.result;
          this.timeCountDown(this.countDownTimeStamp);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/3-AlgorithmCrashCourse.css");
</style>
